import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GlobalService } from "../../services/global.service";
import { TranslateService } from "@ngx-translate/core";
import { InvestigacionService } from "src/app/services/investigacion.service";

@Component({
  selector: "app-centros-investigacion",
  templateUrl: "./centros-investigacion.component.html",
  styleUrls: ["./centros-investigacion.component.css"],
})
export class CentrosInvestigacionComponent implements OnInit {
  public activeLang = "es";
  public Loading: boolean;
  public centro_inv: any;

  constructor(
    private _GlobalService: GlobalService,
    private _InvestigacionService: InvestigacionService,
    private translate: TranslateService
  ) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.GetCentroInvestigacion();
  }

  ngOnInit() { }

  public GetCentroInvestigacion() {
    this.Loading = !this.Loading;
    this._InvestigacionService.GetCentroInvestigacion().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          console.log('centros de investigacion', datos);
          this.centro_inv = datos;

        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

 
}
