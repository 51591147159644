import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class InvestigacionService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = _GlobalService.UrlApi();
    }

    public GetInvestigacionArea() {
        var urlFull = this.url + 'investigacionarea';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    // Centros de investigación

    public GetCentroInvestigacion() {
        var urlFull = this.url + 'centroinvestigacion';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    // Grupos de investigación
    
    public GetGrupoInvestigacion() {
        var urlFull = this.url + 'grupoinvestigacion';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }


    // proyecto_investigacion

    public GetProyectoInvestigacion() {
        var urlFull = this.url + 'proyecto_investigacion';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetProyectoInvestigacionId(id) {
        var urlFull = this.url + 'proyecto_investigacion/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

}
