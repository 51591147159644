import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public activeLang = 'es';

  constructor(private _Router: Router, private translate: TranslateService) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
  }

  ngOnInit(): void {
  }

  public Redireccionar(tipo: number, cod?: string) {
    const rutas = {
      1: `/departamento-detalle/${cod}`,
      2: `/carrera-detalle/${cod}`,
      3: { ruta: '/facultad-historia', sessionKey: 'reload-facultad-historia' },
      4: { ruta: '/facultad-mision-vision', sessionKey: 'reload-facultad-mision-vision' },
      5: { ruta: '/facultad-organigrama', sessionKey: 'reload-facultad-organigrama' },
      6: { ruta: '/vinculacion', sessionKey: 'reload-vinculacion' },
      7: { ruta: '/departamento', sessionKey: 'reload-departamento' },
      8: { ruta: '/pregrado-listado', sessionKey: 'reload-pregrado-listado' },
      9: { ruta: '/postgrado-listado', sessionKey: 'reload-postgrado-listado' },
      10: { ruta: '/investigacion-inicio', sessionKey: 'reload-investigacion' },
      11: { ruta: '/facultad-recursos', sessionKey: 'reload-recursos' },
      12: { ruta: '/vinculacion-medio', sessionKey: 'reload-vinculacion-medio' },
      13: { ruta: '/facultad-recursos-educacion', sessionKey: 'reload-recursos' },
      14: { ruta: '/facultad-recursos-corona', sessionKey: 'reload-recursos' },
      15: { ruta: '/seminarios', sessionKey: 'reload-recursos' },
      16: { ruta: '/grupos-investigacion', sessionKey: 'reload-recursos' },
      17: { ruta: '/centros-investigacion', sessionKey: 'reload-recursos' },
      18: { ruta: '/proyectos-investigacion', sessionKey: 'reload-recursos' },
      19: { ruta: '/ci2030', sessionKey: 'reload-recursos' },
    };
  
    const redireccion = rutas[tipo];
    
    if (redireccion) {
      if (typeof redireccion === 'string') {
        this._Router.navigate([redireccion]);
      } else {
        sessionStorage.setItem(redireccion.sessionKey, JSON.stringify(true));
        this._Router.navigate([redireccion.ruta]);
      }
    } else {
      console.error('Tipo de redirección no válido:', tipo);
    }
  }
  


}
