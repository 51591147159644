import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { GlobalService } from "../../services/global.service";
import { TranslateService } from "@ngx-translate/core";
import { DepartamentoService } from "../../services/departamento.service";
import { SliderService } from "../../services/slider.service";

@Component({
  selector: "app-academico-alumno",
  templateUrl: "./academico-alumno.component.html",
  styleUrls: ["./academico-alumno.component.css"],
})
export class AcademicoAlumnoComponent implements OnInit {
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = "es";

  public estudiante_investigacion = {
    cod_estudiante: 0,
    cod_academico: 0,
    cod_departamento: 0,
    campus: "",
    nombre: "",
    img: "",
    descripcion: "",
    base64: "",
    cambio: 0,
  };

  public Loading: boolean;

  constructor(
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _SliderService: SliderService,
    private _GlobalService: GlobalService,
    private _DepartamentoService: DepartamentoService,
    private translate: TranslateService
  ) {
    this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-academico-alumno"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-academico-alumno", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe((params) => {
      if (params.has("id")) {
        const cod = params.get("id");
        this.Loading = true; // Si decides usar un loader
  
        this._DepartamentoService.GetEstudianteInvestigacionId(cod).subscribe(
          (result) => {
            this.Loading = false; // Ocultar loader al recibir respuesta
            if (result.status === 200) {
              const datos: any = result.body;
              datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
              this.estudiante_investigacion = datos;
              this.estudiante_investigacion.descripcion = this.transformarDescripcion(this.estudiante_investigacion.descripcion);
            } else {
              console.log("result", result);
            }
          },
          (err: HttpErrorResponse) => {
            this.Loading = false; // Ocultar loader en caso de error
            console.error("An error occurred:", err.error instanceof Error ? err.error.message : err);
          }
        );
      } else {
        this._Router.navigate(["/"]);
      }
    });
  }
  
  // Función para transformar la descripción
  private transformarDescripcion(descripcion: string): string {
    const replacements = [
      { from: 'class="ql-font-BAHNSCHRIFT"', to: 'class="display-5"' },
      { from: '<p class="ql-align-justify">', to: '<p class="display-5 ql-align-justify">' },
      { from: "<span s", to: '<span class="display-5" align="justify" s' },
      { from: '<span style="background-color: transparent;">', to: '<span class="display-5" align="justify" style="background-color: transparent;">' },
      { from: '<span style="background-color: transparent; color: rgb(0, 0, 0);">', to: '<span class="display-5" align="justify">' },
      { from: '<strong style="background-color: transparent; color: rgb(0, 0, 0);">', to: '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">' },
      { from: "<h1>", to: '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">' },
      { from: "<li>", to: '<li class="display-5" align="justify">' },
      { from: "<p>", to: '<p class="display-5" align="justify">' },
      { from: "<h1", to: "<h3" },
      { from: "h1>", to: "h3>" },
      { from: "<strong", to: "<h3" },
      { from: "strong>", to: "h3>" },
      { from: "<h3>", to: '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">' },
      { from: "<h3 s", to: '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s' },
    ];
  
    replacements.forEach(replacement => {
      descripcion = descripcion.replace(replacement.from, replacement.to);
    });
  
    return descripcion;
  }
  

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }


}
