import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventoService } from '../../services/evento.service';
import { CarreraService } from '../../services/carrera.service';
import { GlobalService } from '../../services/global.service';
import { DepartamentoService } from '../../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NoticiaService } from '../../services/noticia.service';
import { SliderService } from '../../services/slider.service';

@Component({
  selector: 'app-noticia-busqueda',
  templateUrl: './noticia-busqueda.component.html',
  styleUrls: ['./noticia-busqueda.component.css']
})
export class NoticiaBusquedaComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';
  public textoBusqueda = "";
  public ListadoNoticia:any;
  public Loading: boolean;

  constructor(private _Router: Router, private _NoticiaService:NoticiaService,private _SliderService:SliderService, private _CarreraService: CarreraService,  private _GlobalService: GlobalService,  private _DepartamentoService: DepartamentoService,
   private translate: TranslateService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

   // this.Loading = !this.Loading;
    // let timer = setInterval(() => {
    //   this.Loading = !this.Loading;
    //   clearInterval(timer);
    // }, 1500);
    let info = JSON.parse(sessionStorage.getItem("noticia-busqueda"));
    this.textoBusqueda =  info;
    this.ListadoNoticia = [];
    this.GETNoticiaFiltro();
    this.ListadoBanner = [];
    this.GETBanner();
   }

   public reload(){
    let r = JSON.parse(sessionStorage.getItem("reload-noticia-busqueda"));
    console.log("reload",r);
    if(r == true){
      sessionStorage.setItem("reload-noticia-busqueda", JSON.stringify(false));
      window.location.reload();
    }
  }
  
 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {
  
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public DetalleEvento(evento) {
    let info = {
      Evento: evento
    };
    sessionStorage.setItem("reload-evento-detalle", JSON.stringify(true));
    sessionStorage.setItem("evento_detalle", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(['/evento-detalle']);
  }


  public GETNoticiaFiltro() {
    this._NoticiaService.GETNoticiaFiltro(this.textoBusqueda).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.ListadoNoticia = datos;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public buscarNoticia(){
    console.log(this.textoBusqueda);
    sessionStorage.setItem("noticia-busqueda", JSON.stringify(this.textoBusqueda));
    this.GETNoticiaFiltro();
  }

  public Entrar(event) {
    let inputChar = event.charCode;
    if (inputChar == 13) {
      this.buscarNoticia();
    }
  }

}
