import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cajon-parrafo',
  templateUrl: './cajon-parrafo.component.html',
  styleUrls: ['./cajon-parrafo.component.css']
})
export class CajonParrafoComponent implements OnInit {

  @Input() parrafo: string = '';  // Inicializa con un valor por defecto vacío

  constructor() { }

  ngOnInit(): void {
    this.parrafo = this.AsignarDiseno(this.parrafo);
  }

  public AsignarDiseno(texto: string): string {
    // Expresión regular para capturar todas las etiquetas HTML de apertura
    texto = texto.replace(/<([a-zA-Z1-6]+)(.*?)>/g, '<$1 class="display-5 ql-align-justify"$2>');

    return texto;  // Retorna el texto modificado
  }


}
