import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-botonera-facultad',
  templateUrl: './botonera-facultad.component.html',
  styleUrls: ['./botonera-facultad.component.css']
})
export class BotoneraFacultadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
