import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { CarreraService } from '../../services/carrera.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';
import { NoticiaService } from '../../services/noticia.service';
import { EventoService } from '../../services/evento.service';

@Component({
  selector: 'app-evento-carrera',
  templateUrl: './evento-carrera.component.html',
  styleUrls: ['./evento-carrera.component.css']
})
export class EventoCarreraComponent implements OnInit {

 
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';


  public carrera = {
    cod_carrera: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    tipo: 1,
    web_oficial: "",
    face: "",
    insta: "",
    logo: "",
    base64_logo: "",
    cambio_logo: 0,
    base64: "",
    cambio: 0
  };

 
  public listado_galeria: any;
  public ListadoNoticias: any;
  public ListadoEvento: any;
  public Loading: boolean;


  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, private _SliderService: SliderService, private _CarreraService: CarreraService, private _GlobalService: GlobalService, private _DepartamentoService: DepartamentoService,
    private _NoticiaService: NoticiaService, private _EventoService: EventoService, private translate: TranslateService) {
    this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoBanner = [];
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-carrera-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-carrera-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        // console.log("Codigo super sutro", cod);
        this._CarreraService.GETCarreraID(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              console.log("Carrera", datos);
              if (datos != null) {
                // let img_prueba = "src/archivos/" + datos["img"];
                datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
                datos["logo"] = this._GlobalService.UrlArchivos() + datos["logo"];

                // sessionStorage.setItem("img_prueba", JSON.stringify(img_prueba));
                sessionStorage.setItem("reload-carrera_detalle", JSON.stringify(true));
                sessionStorage.setItem("carrera_detalle", JSON.stringify(datos));
                this.carrera = datos;
                this.GETEvento();
                if (this.carrera.tipo == 1) {
                  this.GETBanner(3);
                } else {
                  this.GETBanner(4);
                }
              }
            } else {
              console.log('result', result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
          }
        );

      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public GETEvento() {
    this._EventoService.GetCarreraEventos(this.carrera.cod_carrera).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            element["hora_inicio"] = element["hora_inicio"].substring(0, 5);
            element["hora_termino"] = element["hora_termino"].substring(0, 5);

          });
          console.log(datos);

          this.ListadoEvento = datos;
          console.log(this.ListadoEvento.length);

        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }








  public RedirectPag(url) {
    window.open(url);
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner(tipo) {
    this._SliderService.GETBannerId(tipo).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public VerGaleria() {
    sessionStorage.setItem("reload-galeria_carrera", JSON.stringify(true));
    sessionStorage.setItem("galeria_carrera", JSON.stringify(this.carrera));
    this._Router.navigate(['/galeria-carrera/' + this.carrera.cod_carrera]);
  }

  public VerNoticias() {
    sessionStorage.setItem("reload-noticia_carrera", JSON.stringify(true));
    sessionStorage.setItem("noticia_carrera", JSON.stringify(this.carrera));
    this._Router.navigate(['/noticia-carrera/' + this.carrera.cod_carrera]);
  }

  public VerEventos() {
    sessionStorage.setItem("reload-evento_carrera", JSON.stringify(true));
    sessionStorage.setItem("evento_carrera", JSON.stringify(this.carrera));
    this._Router.navigate(['/evento-carrera/' + this.carrera.cod_carrera]);
  }
  
}
