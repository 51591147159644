import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../../../services/global.service";
import { DepartamentoService } from "../../../../services/departamento.service";
import { TranslateService } from "@ngx-translate/core";
import { CarreraService } from "../../../../services/carrera.service";
import { HttpErrorResponse } from "@angular/common/http";
import { SliderService } from "../../../../services/slider.service";

@Component({
  selector: "app-invest-acad-carlos",
  templateUrl: "./invest-acad-carlos.component.html",
  styleUrls: ["./invest-acad-carlos.component.css"],
})
export class InvestAcadCarlosComponent implements OnInit {
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = "es";
  public imgPrincipal = "assets/imgs/historia/facultad.png";

  public matematica: boolean;
  public quimica: boolean;
  public fisica: boolean;
  public estadistica: boolean;
  public cs_basicas: boolean;

  constructor(
    private _Router: Router,
    private _GlobalService: GlobalService,
    private _SliderService: SliderService,
    private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService,
    private translate: TranslateService
  ) {
    this.reload();

    this.matematica = true;
    this.quimica = false;
    this.fisica = false;
    this.estadistica = false;
    this.cs_basicas = false;

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-facultad-mision-vision"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem(
        "reload-facultad-mision-vision",
        JSON.stringify(false)
      );
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  ngOnInit() {}

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public InfoDepto(tipo: number) {
    const departamentos = {
      1: {
        matematica: true,
        quimica: false,
        fisica: false,
        estadistica: false,
        cs_basicas: false,
      },
      2: {
        matematica: false,
        quimica: true,
        fisica: false,
        estadistica: false,
        cs_basicas: false,
      },
      3: {
        matematica: false,
        quimica: false,
        fisica: true,
        estadistica: false,
        cs_basicas: false,
      },
      4: {
        matematica: false,
        quimica: false,
        fisica: false,
        estadistica: true,
        cs_basicas: false,
      },
      5: {
        matematica: false,
        quimica: false,
        fisica: false,
        estadistica: false,
        cs_basicas: true,
      },
    };

    const info = departamentos[tipo];

    if (info) {
      this.matematica = info.matematica;
      this.quimica = info.quimica;
      this.fisica = info.fisica;
      this.estadistica = info.estadistica;
      this.cs_basicas = info.cs_basicas;
    } else {
      console.error("Tipo de departamento no válido:", tipo);
    }
  }
}
