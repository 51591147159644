import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GlobalService } from "../../services/global.service";
import { TranslateService } from "@ngx-translate/core";
import { InvestigacionService } from "src/app/services/investigacion.service";

@Component({
  selector: 'app-proyectos-investigacion',
  templateUrl: './proyectos-investigacion.component.html',
  styleUrls: ['./proyectos-investigacion.component.css']
})
export class ProyectosInvestigacionComponent implements OnInit {

  public activeLang = "es";
  public Loading: boolean;
  public proyecto_investigacion: any;

  constructor(
    private _GlobalService: GlobalService,
    private _InvestigacionService: InvestigacionService,
    private translate: TranslateService
  ) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.GetProyectoInvestigacion();
  }

  ngOnInit(): void {
  }

  public GetProyectoInvestigacion() {
    this.Loading = !this.Loading;
    this._InvestigacionService.GetProyectoInvestigacion().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          console.log('proyecto_investigacion', datos);
          this.proyecto_investigacion = datos;

        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

}
