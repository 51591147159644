import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartamentoService } from '../../services/departamento.service';
import { GlobalService } from '../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CarreraService } from '../../services/carrera.service';
import { TranslateService } from '@ngx-translate/core';
import { SliderService } from '../../services/slider.service';

@Component({
  selector: 'app-funcionario-detalle',
  templateUrl: './funcionario-detalle.component.html',
  styleUrls: ['./funcionario-detalle.component.css']
})
export class FuncionarioDetalleComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public funcionario: any;
  public departamento: any;
  public tipo: any;
  public listadoTitulo: any;
  public listadoCargo: any;
  public listadoPublicacion: any;
  public listadoBecaDist: any;
  public Loading: boolean;

  constructor(private _Router: Router, private _DepartamentoService: DepartamentoService,private _SliderService:SliderService, private _GlobalService: GlobalService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.Loading = !this.Loading;
    this.tipo = JSON.parse(sessionStorage.getItem("funcionario_tipo"));
    let datos = JSON.parse(sessionStorage.getItem("funcionario_detalle"));
    this.funcionario = datos.funcionario;
    this.departamento = datos.departamento;
    this.listadoTitulo = [];
    this.listadoCargo = [];
    this.listadoPublicacion = [];
    this.listadoBecaDist = [];

    let timer = setInterval(() => {
      this.Loading = !this.Loading;
      clearInterval(timer);
    }, 1500);

    this.GetFuncionarioCurriculum();
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-funcionario-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-funcionario-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {

  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }


  public GetFuncionarioCurriculum() {
    this.Loading = !this.Loading;
    this._DepartamentoService.GetFuncionarioCurriculum(this.funcionario.cod_funcionario).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          console.log('Funcionario', datos);

          this.listadoTitulo = datos.filter(function (element) {
            return element.tipo == 1;
          });

          this.listadoCargo = datos.filter(function (element) {
            return element.tipo == 2;
          });

          this.listadoPublicacion = datos.filter(function (element) {
            return element.tipo == 3;
          });

          this.listadoBecaDist = datos.filter(function (element) {
            return element.tipo == 4;
          });

        } else {
          console.log('result', result)
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

}
