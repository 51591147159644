import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GlobalService } from "../../services/global.service";
import { CarreraService } from "../../services/carrera.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SliderService } from "../../services/slider.service";
import { NoticiaService } from "../../services/noticia.service";
import { EventoService } from "../../services/evento.service";

@Component({
  selector: "app-carrera-detalle",
  templateUrl: "./carrera-detalle.component.html",
  styleUrls: ["./carrera-detalle.component.css"],
})
export class CarreraDetalleComponent implements OnInit {
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = "es";

  public carrera_extra = { cod_extra: 0, cod_carrera: 0, descripcion: "" };

  public carrera = {
    cod_carrera: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    tipo: 1,
    web_oficial: "",
    face: "",
    insta: "",
    logo: "",
    base64_logo: "",
    cambio_logo: 0,
    base64: "",
    cambio: 0,
  };

  public carrera_detalle = {
    cod_detalle: 0,
    cod_carrera: 0,
    descripcion_inicial: "",
    descripcion_inicial_ingles: "",
    ficha_carrera_pdf: "",
    ficha_carrera_pdf_base64: "",
    ficha_carrera_pdf_cambio: 0,
    malla_curricular_pdf: "",
    malla_curricular_pdf_base64: "",
    malla_curricular_pdf_cambio: 0,
    nem: 0,
    ranking: 0,
    lenguaje: 0,
    matematica: 0,
    matematica2: 0,
    historia_geografia: 0,
    ciencias: 0,
    cuota_basica: 0,
    arancel: 0,
    descripcion: "",
    descripcion_ingles: "",
    perfil_egreso: "",
    perfil_egreso_ingles: "",
    malla_curricular: "",
    malla_curricular_base64: "",
    malla_curricular_cambio: 0,
  };

  public carrera_contacto = {
    cod_contacto: 0,
    cod_carrera: 0,
    nombre: "",
    cargo: "",
    sede: "",
    correo: "",
    ubicacion: "",
    fono: "",
    img: "",
    base64: "",
    cambio: 0,
  };

  public carrera_contacto_director = {
    cod_contacto: 0,
    cod_carrera: 0,
    nombre: "",
    cargo: "",
    sede: "",
    correo: "",
    ubicacion: "",
    fono: "",
    img: "",
    base64: "",
    cambio: 0,
  };

  public Galeria = {
    galeria1: {},
    galeria2: {},
    galeria3: {},
    galeria4: {},
    galeria5: {},
    galeria6: {},
  };

  public listado_galeria: any;
  public ListadoNoticias: any;
  public ListadoEvento: any;
  public Loading: boolean;

  constructor(
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _SliderService: SliderService,
    private _CarreraService: CarreraService,
    private _GlobalService: GlobalService,
    private _NoticiaService: NoticiaService,
    private _EventoService: EventoService,
    private translate: TranslateService
  ) {
    this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-carrera-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-carrera-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe((params) => {
      if (params.has("id")) {
        let cod = params.get("id");
        this.carrera.cod_carrera = Number(cod);
        this._CarreraService.GETCarreraID(cod).subscribe(
          (result) => {
            if (result.status == 200) {
              let datos: any = result.body;
              console.log("Carrera", datos);
              if (datos != null) {
                // let img_prueba = "src/archivos/" + datos["img"];
                datos["nombre"] = datos["nombre"].toUpperCase();
                datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
                datos["logo"] =
                  this._GlobalService.UrlArchivos() + datos["logo"];

                // sessionStorage.setItem("img_prueba", JSON.stringify(img_prueba));
                sessionStorage.setItem(
                  "reload-carrera_detalle",
                  JSON.stringify(true)
                );
                sessionStorage.setItem(
                  "carrera_detalle",
                  JSON.stringify(datos)
                );
                this.carrera = datos;
                this.GetCarreraGaleria();
                this.GetCarreraContacto();
                this.GetCarreraContactoDirector();
                this.GetCarreraDetalle();
                this.GetCarreraGaleria();
                this.GetCarreraExtra();
                this.GETNoticia();
                this.GETEvento();
                if (this.carrera.tipo == 1) {
                  this.GETBanner(3);
                } else {
                  this.GETBanner(4);
                }
              }
            } else {
              console.log("result", result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("An error occurred:", err.error.message);
            }
          }
        );
      } else {
        this._Router.navigate(["/"]);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public AbrirVista(vista: number, tipo: any) {
    const paginas: { [key: number]: string } = {
      1: "/academicos-carrera-listado/" + this.carrera.cod_carrera,
      2: "/lineas-investigacion/" + this.carrera.cod_carrera,
      3: "/laboratorios/" + this.carrera.cod_carrera,
      4: "/alumnos-listado/" + this.carrera.cod_carrera,
    };

    const pagina = paginas[vista];

    if (pagina) {
      console.log(tipo);
      this._Router.navigate([pagina]);
    } else {
      console.error("Vista no válida:", vista);
    }
  }

  public GETNoticia() {
    this._NoticiaService.GetCarreraNoticias(this.carrera.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          let listado = [];

          if (datos.length <= 3) {
            for (let i = 0; i < datos.length; i++) {
              listado.push(datos[i]);
            }
          } else {
            for (let i = 0; i < 3; i++) {
              listado.push(datos[i]);
            }
          }

          // this.OrdenarNoticias(datos);
          this.ListadoNoticias = listado;
          sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public GETEvento() {
    this._EventoService.GetCarreraEventos(this.carrera.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            element["hora_inicio"] = element["hora_inicio"].substring(0, 5);
            element["hora_termino"] = element["hora_termino"].substring(0, 5);
          });
          console.log(datos);
          let listado = [];

          if (datos.length <= 3) {
            for (let i = 0; i < datos.length; i++) {
              listado.push(datos[i]);
            }
          } else {
            for (let i = 0; i < 3; i++) {
              listado.push(datos[i]);
            }
          }

          this.ListadoEvento = listado;
          console.log(this.ListadoEvento.length);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public GetCarreraExtra() {
    this.Loading = !this.Loading;
    console.log("cod carrera", this.carrera.cod_carrera);

    this._CarreraService.GetCarreraExtra(this.carrera.cod_carrera).subscribe(
      (result) => {
        if (result.status === 200) {
          let datos: any = result.body;
          console.log("Datos extra", datos);
          this.carrera_extra = datos;
          this.carrera_extra.cod_carrera = this.carrera.cod_carrera;

          this.processDescripcion(this.carrera_extra.descripcion);
        } else {
          console.log("result", result);
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  private processDescripcion(descripcion: string): void {
    const replacements: [string, string][] = [
      ['class="ql-font-BAHNSCHRIFT"', 'class="display-5"'],
      [
        '<p class="ql-align-justify">',
        '<p class="display-5 ql-align-justify" align="justify">',
      ],
      ["<span s", '<span class="display-5" align="justify" s'],
      [
        '<span style="background-color: transparent;">',
        '<span class="display-5" align="justify" style="background-color: transparent;">',
      ],
      [
        '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
        '<span class="display-5" align="justify">',
      ],
      [
        '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
        '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">',
      ],
      [
        "<h1>",
        '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">',
      ],
      ["<li>", '<li class="display-5" align="justify">'],
      ["<p>", '<p class="display-5" align="justify">'],
      ["<h1", "<h3"],
      ["h1>", "h3>"],
      ["<strong", "<h3"],
      ["strong>", "h3>"],
      [
        "<h3>",
        '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2editor">',
      ],
      [
        "<h3 s",
        '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2editor" s',
      ],
      [
        '<h3 class="ql-size-huge">',
        '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2editor">',
      ],
      ["<ul>", '<ul class="listaul display-5" style="font-size: 1rem;">'],
      ["<li>", '<li align="justify">'],
    ];

    for (const [searchValue, newValue] of replacements) {
      descripcion = descripcion.replace(new RegExp(searchValue, "g"), newValue);
    }

    this.carrera_extra.descripcion = descripcion;
  }

  public GetCarreraGaleria() {
    this.Loading = !this.Loading;
    this._CarreraService.GetCarreraGaleria(this.carrera.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          datos.forEach((element) => {
            element.base64 = this._GlobalService.UrlArchivos() + element.base64;
          });
          // console.log('Carrera Galeria', JSON.stringify(datos));
          this.listado_galeria = datos;
          this.OrdenarGaleria(datos);
        } else {
          console.log("result", result);
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public GetCarreraDetalle() {
    this.Loading = !this.Loading;
    this._CarreraService.GetCarreraDetalle(this.carrera.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          this.carrera_detalle = datos;
          this.carrera_detalle.ficha_carrera_pdf_base64 =
            this._GlobalService.UrlArchivos() +
            this.carrera_detalle.ficha_carrera_pdf_base64;
          this.carrera_detalle.malla_curricular_pdf_base64 =
            this._GlobalService.UrlArchivos() +
            this.carrera_detalle.malla_curricular_pdf_base64;
          this.carrera_detalle.malla_curricular_base64 =
            this._GlobalService.UrlArchivos() +
            this.carrera_detalle.malla_curricular_base64;
          this.carrera_detalle.cod_carrera = this.carrera.cod_carrera;

          // console.log('Carrera Detalle', JSON.stringify(datos));
        } else {
          console.log("result", result);
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public GetCarreraContacto() {
    this.Loading = !this.Loading;
    this._CarreraService.GetCarreraContacto(this.carrera.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          this.carrera_contacto = datos;
          this.carrera_contacto.base64 =
            this._GlobalService.UrlArchivos() + this.carrera_contacto.base64;
          this.carrera_contacto.cod_carrera = this.carrera.cod_carrera;

          // console.log('Carrera Contacto', JSON.stringify(datos));
        } else {
          console.log("result", result);
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public GetCarreraContactoDirector() {
    this.Loading = !this.Loading;
    this._CarreraService
      .GetCarreraContactoDirector(this.carrera.cod_carrera)
      .subscribe(
        (result) => {
          if (result.status == 200) {
            let datos: any = result.body;
            this.carrera_contacto_director = datos;
            this.carrera_contacto_director.base64 =
              this._GlobalService.UrlArchivos() +
              this.carrera_contacto_director.base64;
            this.carrera_contacto_director.cod_carrera =
              this.carrera.cod_carrera;

            console.log("Carrera Contacto", JSON.stringify(datos));
          } else {
            console.log("result", result);
          }
          this.Loading = !this.Loading;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("An error occurred:", err.error.message);
          }
          this.Loading = !this.Loading;
        }
      );
  }

  public AbrirDocumento(tipo) {
    let url = "";
    switch (tipo) {
      case 1:
        url = this.carrera_detalle.ficha_carrera_pdf_base64;
        break;
      case 2:
        url = this.carrera_detalle.malla_curricular_pdf_base64;
        break;
    }
    window.open(url);
  }

  public OrdenarGaleria(datos) {
    const maxGaleriaItems = 6; // Número máximo de galerías
    const galeriaKeys = [
      'galeria1',
      'galeria2',
      'galeria3',
      'galeria4',
      'galeria5',
      'galeria6'
    ];
  
    for (let i = 0; i < Math.min(datos.length, maxGaleriaItems); i++) {
      this.Galeria[galeriaKeys[i]] = datos[i];
    }
  }
  

  public RedirectPag(url) {
    window.open(url);
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner(tipo) {
    this._SliderService.GETBannerId(tipo).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public VerGaleria() {
    sessionStorage.setItem("reload-galeria_carrera", JSON.stringify(true));
    sessionStorage.setItem("galeria_carrera", JSON.stringify(this.carrera));
    this._Router.navigate(["/galeria-carrera/" + this.carrera.cod_carrera]);
  }

  public VerNoticias() {
    sessionStorage.setItem("reload-noticia_carrera", JSON.stringify(true));
    sessionStorage.setItem("noticia_carrera", JSON.stringify(this.carrera));
    this._Router.navigate(["/noticia-carrera/" + this.carrera.cod_carrera]);
  }

  public VerEventos() {
    sessionStorage.setItem("reload-evento_carrera", JSON.stringify(true));
    sessionStorage.setItem("evento_carrera", JSON.stringify(this.carrera));
    this._Router.navigate(["/evento-carrera/" + this.carrera.cod_carrera]);
  }
}
