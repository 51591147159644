import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cajon-titulo',
  templateUrl: './cajon-titulo.component.html',
  styleUrls: ['./cajon-titulo.component.css']
})
export class CajonTituloComponent implements OnInit {

  @Input() titulo: string;

  constructor() { }

  ngOnInit(): void {
  }

}
