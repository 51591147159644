import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { CarreraService } from '../../services/carrera.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';
import { NoticiaService } from '../../services/noticia.service';
import { EventoService } from '../../services/evento.service';


@Component({
  selector: 'app-noticia-carrera',
  templateUrl: './noticia-carrera.component.html',
  styleUrls: ['./noticia-carrera.component.css']
})
export class NoticiaCarreraComponent implements OnInit {

  
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public carrera_extra = { cod_extra: 0, cod_carrera: 0, descripcion: "" };

  public carrera = {
    cod_carrera: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    tipo: 1,
    web_oficial: "",
    face: "",
    insta: "",
    logo: "",
    base64_logo: "",
    cambio_logo: 0,
    base64: "",
    cambio: 0
  };

  public carrera_detalle = {
    cod_detalle: 0,
    cod_carrera: 0,
    descripcion_inicial: "",
    descripcion_inicial_ingles: "",
    ficha_carrera_pdf: "",
    ficha_carrera_pdf_base64: "",
    ficha_carrera_pdf_cambio: 0,
    malla_curricular_pdf: "",
    malla_curricular_pdf_base64: "",
    malla_curricular_pdf_cambio: 0,
    nem: 0,
    ranking: 0,
    lenguaje: 0,
    matematica: 0,
    historia_geografia: 0,
    ciencias: 0,
    cuota_basica: 0,
    arancel: 0,
    descripcion: "",
    descripcion_ingles: "",
    perfil_egreso: "",
    perfil_egreso_ingles: "",
    malla_curricular: "",
    malla_curricular_base64: "",
    malla_curricular_cambio: 0
  };

  public carrera_contacto = {
    cod_contacto: 0,
    cod_carrera: 0,
    nombre: "",
    cargo: "",
    sede: "",
    correo: "",
    ubicacion: "",
    fono: "",
    img: "",
    base64: "",
    cambio: 0
  };

  public carrera_contacto_director = {
    cod_contacto: 0,
    cod_carrera: 0,
    nombre: "",
    cargo: "",
    sede: "",
    correo: "",
    ubicacion: "",
    fono: "",
    img: "",
    base64: "",
    cambio: 0
  };

  public Galeria = {
    galeria1: {},
    galeria2: {},
    galeria3: {},
    galeria4: {},
    galeria5: {},
    galeria6: {},
  };

  public listado_galeria: any;
  public ListadoNoticias: any;
  public ListadoEvento: any;
  public Loading: boolean;


  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, private _SliderService: SliderService, private _CarreraService: CarreraService, private _GlobalService: GlobalService, private _DepartamentoService: DepartamentoService,
    private _NoticiaService: NoticiaService, private _EventoService: EventoService, private translate: TranslateService) {
    this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-carrera-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-carrera-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        // console.log("Codigo super sutro", cod);
        this._CarreraService.GETCarreraID(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              console.log("Carrera", datos);
              if (datos != null) {
                // let img_prueba = "src/archivos/" + datos["img"];
                datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
                datos["logo"] = this._GlobalService.UrlArchivos() + datos["logo"];

                // sessionStorage.setItem("img_prueba", JSON.stringify(img_prueba));
                sessionStorage.setItem("reload-carrera_detalle", JSON.stringify(true));
                sessionStorage.setItem("carrera_detalle", JSON.stringify(datos));
                this.carrera = datos;
                this.GETNoticia();
                if (this.carrera.tipo == 1) {
                  this.GETBanner(3);
                } else {
                  this.GETBanner(4);
                }
              }
            } else {
              console.log('result', result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
          }
        );

      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public GETNoticia() {
    this._NoticiaService.GetCarreraNoticias(this.carrera.cod_carrera).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.ListadoNoticias = datos;
          sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }
  public RedirectPag(url) {
    window.open(url);
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner(tipo) {
    this._SliderService.GETBannerId(tipo).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

}
