import { Component, OnInit } from "@angular/core";
import { GlobalService } from "../../services/global.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { EventoService } from "../../services/evento.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'app-evento-inicio',
  templateUrl: './evento-inicio.component.html',
  styleUrls: ['./evento-inicio.component.css']
})
export class EventoInicioComponent implements OnInit {

  public activeLang = "es";
  public ListadoEventos: any = [];
  public Eventos = {
    evento1: {},
    evento2: {},
  }
  public EventosPasados = {
    evento1: {},
    evento2: {},
    evento3: {},
  }
  public Loading: boolean;

  constructor(
    private _Router: Router,
    private _GlobalService: GlobalService,
    private _EventoService: EventoService,
    private translate: TranslateService,
    public sanitizer: DomSanitizer
  ) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.GETEvento();
  }

  ngOnInit() { }

  public GETEvento() {
    this._EventoService.GETEvento().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let fecha_actual = new Date();
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            element["hora_inicio"] = element["hora_inicio"].substring(0, 5);
            element["hora_termino"] = element["hora_termino"].substring(0, 5);
            element["desc_corto"] = this.truncarTexto(element["descripcion"], 80);
            element["desc_corto_ingles"] = this.truncarTexto(element["descripcion_ingles"], 80);

          });
          console.log("Listado evento", datos);
          let listado_mayor = datos.filter(function (element) {
            return new Date(element.fecha_inicio) >= fecha_actual;
          });
          console.log("Listado mayor", listado_mayor);
          let listado_menor = datos.filter(function (element) {
            return new Date(element.fecha_inicio) < fecha_actual;
          });
          console.log("Listado menor", listado_menor);
          // this.OrdenarEventos(datos);
          // this.OrdenarEventos(listado_mayor);
          // this.OrdenarEventosPasados(listado_menor);
          this.ListadoEventos = datos;

          this.OrdenarEventos(datos, this.Eventos, 2);
          this.OrdenarEventos(listado_menor, this.EventosPasados, 3);

          sessionStorage.setItem("evento_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }



  // public GETEvento() {
  //   this._EventoService.GETEvento().subscribe(
  //     (result) => {
  //       if (result.status === 200) {
  //         let datos: any = result.body;
  //         let fecha_actual = new Date();

  //         datos.forEach((element) => {
  //           element["img"] = this._GlobalService.UrlArchivos() + element["img"];
  //           element["hora_inicio"] = element["hora_inicio"].substring(0, 5);
  //           element["hora_termino"] = element["hora_termino"].substring(0, 5);

  //           element["desc_corto"] = this.truncarTexto(element["descripcion"], 80);
  //           element["desc_corto_ingles"] = this.truncarTexto(element["descripcion_ingles"], 80);
  //         });

  //         let listado_futuros = datos.filter((element) => new Date(element.fecha_inicio) >= fecha_actual);
  //         let listado_pasados = datos.filter((element) => new Date(element.fecha_inicio) < fecha_actual);

  //         if (listado_futuros.length === 0) {
  //           this.OrdenarEventos(datos, this.Eventos, 2);
  //           datos.splice(0, 2);
  //           this.OrdenarEventos(datos, this.EventosPasados, 3);
  //         } else {
  //           this.OrdenarEventos(listado_futuros, this.Eventos, 2);
  //           this.OrdenarEventos(listado_pasados, this.EventosPasados, 3);
  //         }



  //         console.log("eventos", this.Eventos);
  //         console.log("eventos pasados", this.EventosPasados);

  //         this.ListadoEventos = datos;
  //         sessionStorage.setItem("evento_listado", JSON.stringify(datos));
  //       } else {
  //         console.log("result", result);
  //       }
  //     },
  //     (err: HttpErrorResponse) => {
  //       if (err.error instanceof Error) {
  //         console.log("An error occurred:", err.error.message);
  //       }
  //     }
  //   );
  // }

  public OrdenarEventos(datos, target, limite) {
    datos.slice(0, limite).forEach((element, index) => {
      target[`evento${index + 1}`] = element;
    });
  }

  public truncarTexto(texto: string, limite: number): string {
    return texto.length > limite ? texto.substring(0, limite) + "..." : texto;
  }

  public DetalleEvento(evento) {
    let info = { Evento: evento };
    sessionStorage.setItem("reload-evento_detalle", JSON.stringify(true));
    sessionStorage.setItem("evento_detalle", JSON.stringify(info));
    this._Router.navigate(["/evento-detalle/" + evento.cod_evento]);
  }

}
