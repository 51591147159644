import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { CarreraService } from "../../services/carrera.service";
import { GlobalService } from "../../services/global.service";
import { TranslateService } from "@ngx-translate/core";
import { DepartamentoService } from "../../services/departamento.service";
import { SliderService } from "../../services/slider.service";
declare var VanillaTilt;

@Component({
  selector: "app-alumno-detalle",
  templateUrl: "./alumno-detalle.component.html",
  styleUrls: ["./alumno-detalle.component.css"],
})
export class AlumnoDetalleComponent implements OnInit {
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = "es";

  public alumno = {
    cod_alumno: 0,
    cod_carrera: 0,
    nombre: "",
    pregrado: "",
    pregrado_institucion: "",
    postgrado: "",
    postgrado_institucion: "",
    img: "",
  };
  public info_general = {
    cod_curriculum: 0,
    cod_alumno: 0,
    descripcion: "",
    fecha: null,
    tipo: "General",
    tipo_texto: 2,
    orden: 0,
  };

  public info_general_ingles = {
    cod_curriculum: 0,
    cod_alumno: 0,
    descripcion: "",
    fecha: null,
    tipo: "General_Ingles",
    tipo_texto: 2,
    orden: 0,
  };

  public info_biografia = {
    cod_curriculum: 0,
    cod_alumno: 0,
    descripcion: "",
    fecha: null,
    tipo: "Biografia",
    tipo_texto: 2,
    orden: 0,
  };

  public info_biografia_ingles = {
    cod_curriculum: 0,
    cod_alumno: 0,
    descripcion: "",
    fecha: null,
    tipo: "Biografia_Ingles",
    tipo_texto: 2,
    orden: 0,
  };

  public general: boolean;
  public publicacion: boolean;
  public investigacion: boolean;
  public ensenaza: boolean;
  public biografia: boolean;
  public supervision: boolean;

  public listadoGeneral: any;
  public listadoGeneralIngles: any;
  public listadoPublicacion: any;
  public listadoInvestigacion: any;
  public listadoEnsenaza: any;
  public listadoBiografia: any;
  public listadoBiografiaIngles: any;

  public ListadoEstudiantes: any;

  public Loading: boolean;
  public TextoHexag = "";

  constructor(
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _SliderService: SliderService,
    private _GlobalService: GlobalService,
    private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService,
    private translate: TranslateService
  ) {
    this.reload();
    this.Loading = !this.Loading;
    // let info = JSON.parse(sessionStorage.getItem("alumno_detalle"));
    // this.alumno = info;
    //info
    this.general = true;
    this.publicacion = false;
    this.investigacion = false;
    this.ensenaza = false;
    this.biografia = false;
    this.supervision = false;
    this.listadoGeneral = [];
    this.listadoPublicacion = [];
    this.listadoInvestigacion = [];
    this.listadoEnsenaza = [];
    this.listadoBiografia = [];
    //
    let timer = setInterval(() => {
      this.Loading = !this.Loading;
      clearInterval(timer);
    }, 1500);
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    // this.GetalumnoCurriculum();
    // this.GetEstudianteInvestigacion()
    this.ListadoBanner = [];
    this.GETBanner();
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe((params) => {
      if (params.has("id")) {
        let cod = params.get("id");
        // this.Loading = !this.Loading;
        this._CarreraService.GetAlumnosCarreraId(cod).subscribe(
          (result) => {
            if (result.status == 200) {
              let datos: any = result.body;
              datos = datos[0];
              datos["img"] =
                this._GlobalService.UrlArchivos() + "alumnos/" + datos["img"];
              this.alumno = datos;
              console.log(JSON.stringify(datos));

              this.GetAlumnoCurriculumTipo("General");
              this.GetAlumnoCurriculumTipo("General_Ingles");
              this.GetAlumnoCurriculumTipo("Publicacion");
              this.GetAlumnoCurriculumTipo("Investigacion");
              this.GetAlumnoCurriculumTipo("Enseñanza");
              this.GetAlumnoCurriculumTipo("Biografia");
              this.GetAlumnoCurriculumTipo("Biografia_Ingles");
            } else {
              console.log("result", result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("An error occurred:", err.error.message);
            }
          }
        );
      } else {
        this._Router.navigate(["/"]);
      }
    });
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-alumno-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-alumno-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }


  public InfoCurriculum(tipo) {
    switch (tipo) {
      case 1:
        this.general = true;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 2:
        this.general = false;
        this.publicacion = true;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 3:
        this.general = false;
        this.publicacion = false;
        this.investigacion = true;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 4:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = true;
        this.biografia = false;
        this.supervision = false;
        break;
      case 5:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = true;
        this.supervision = false;
        break;
      case 6:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = true;
        break;
    }
  }

  public formatoPublicacion(contenido: string) {
    // Reemplazar **Texto** por <strong>Texto</strong>
    contenido = contenido.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    // Reemplazar *Texto* por <i>Texto</i>
    contenido = contenido.replace(/\*(.*?)\*/g, "<i>$1</i>");

    return contenido;
  }

  public GetAlumnoCurriculumTipo(tipo) {
    this.Loading = !this.Loading;
    this._CarreraService
      .GetAlumnoCurriculumTipo(this.alumno.cod_alumno, tipo)
      .subscribe(
        (result) => {
          if (result.status == 200) {
            let datos: any = result.body;
            console.log("alumno", datos);
            switch (tipo) {
              case "General":
                this.listadoGeneral = datos;
                datos.forEach((element) => {
                  this.info_general = element;
                });
                for (let index = 0; index < 100; index++) {
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("<h1", "<h3");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("h1>", "h3>");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("<strong", "<h3");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("strong>", "h3>");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
              case "General_Ingles":
                this.listadoGeneralIngles = datos;
                datos.forEach((element) => {
                  this.info_general_ingles = element;
                });
                for (let index = 0; index < 100; index++) {
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace("<h1", "<h3");
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace("h1>", "h3>");
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<strong",
                      "<h3"
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "strong>",
                      "h3>"
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
              case "Publicacion":
                datos.forEach((element) => {
                  element.descripcion = this.formatoPublicacion(
                    element.descripcion
                  );
                });
                this.listadoPublicacion = datos;
                break;
              case "Investigacion":
                this.listadoInvestigacion = datos;
                break;
              case "Enseñanza":
                this.listadoEnsenaza = datos;
                break;
              case "Biografia":
                this.listadoBiografia = datos;
                datos.forEach((element) => {
                  this.info_biografia = element;
                });

                for (let index = 0; index < 100; index++) {
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("<h1", "<h3");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("h1>", "h3>");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("<strong", "<h3");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("strong>", "h3>");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
              case "Biografia_Ingles":
                this.listadoBiografiaIngles = datos;
                datos.forEach((element) => {
                  this.info_biografia_ingles = element;
                });

                for (let index = 0; index < 100; index++) {
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h1",
                      "<h3"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "h1>",
                      "h3>"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<strong",
                      "<h3"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "strong>",
                      "h3>"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
            }

            // console.log("Listado General", JSON.stringify(datos));
          } else {
            console.log("result", result);
            // this._GlobalService.ToastError();
          }
          this.Loading = !this.Loading;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("An error occurred:", err.error.message);
            // this._GlobalService.ToastError();
          }
          this.Loading = !this.Loading;
        }
      );
  }

  public GetEstudianteInvestigacion() {
    this._DepartamentoService
      .GetEstudianteInvestigacion(this.alumno.cod_alumno)
      .subscribe(
        (result) => {
          if (result.status == 200) {
            let datos: any = result.body;
            datos.forEach((element) => {
              element["img"] =
                this._GlobalService.UrlArchivos() + element["img"];
            });
            this.ListadoEstudiantes = datos;
            console.log(datos);
            sessionStorage.setItem("estudiante_listado", JSON.stringify(datos));
          } else {
            console.log("result", result);
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("An error occurred:", err.error.message);
          }
        }
      );
  }

  public MostrarTituloHex(texto) {
    this.TextoHexag = texto;
  }

  public DetalleEstudiante(estudiante) {
    let info = {
      estudiante: estudiante,
    };
    sessionStorage.setItem("reload-alumno-alumno", JSON.stringify(true));
    sessionStorage.setItem("alumno-alumno", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(["/alumno-alumno/" + estudiante.cod_estudiante]);
  }
}
