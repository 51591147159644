import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class Ci2030Service {
  private url: string;
  constructor(
    private _HttpClient: HttpClient,
    private _GlobalService: GlobalService
  ) {
    this.url = _GlobalService.UrlApi();
  }

  // ci2030

  public GetCi2030() {
    var urlFull = this.url + "ci2030";
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

  public GetCi2030Id(id) {
    var urlFull = this.url + "ci2030/" + id;
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

  // ci2030_autoridad

  public GetCi2030Autoridad() {
    var urlFull = this.url + "ci2030_autoridad";
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

  public GetCi2030AutoridadId(id) {
    var urlFull = this.url + "ci2030_autoridad/" + id;
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

  // ci2030_carpeta

  public GetCi2030Carpeta() {
    var urlFull = this.url + "ci2030_carpeta";
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

  public GetCi2030CarpetaId(id) {
    var urlFull = this.url + "ci2030_carpeta/" + id;
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

  // ci2030_video

  public GetCi2030Video() {
    var urlFull = this.url + "ci2030_video";
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

  public GetCi2030VideoId(id) {
    var urlFull = this.url + "ci2030_video/" + id;
    console.log(urlFull);
    return this._HttpClient.get(urlFull, { observe: "response" });
  }

}
