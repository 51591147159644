import { Component, OnInit } from "@angular/core";
import { GlobalService } from "../services/global.service";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { Ci2030Service } from "../services/ci2030.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
declare var VanillaTilt;

@Component({
  selector: "app-ci2030",
  templateUrl: "./ci2030.component.html",
  styleUrls: ["./ci2030.component.css"],
})
export class Ci2030Component implements OnInit {
  public ListadoBanner: any;
  public ListadoActividades: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = "es";

  public ListadoHexagono: any;
  public Loading: boolean;
  public imgPrincipal = "assets/imgs/historia/facultad.png";

  public img_1 = "assets/imgs/corona/BANNER COVID ESTADISTICA.png";
  public link_1 = "http://estadistica.ubiobio.cl/?page_id=176";

  public img_2 = "assets/imgs/corona/BANNER COVID UFI-07.png";
  public link_2 = "http://ufi.ubiobio.cl/web/index.php/site/guia/16";

  public img_3 = "assets/imgs/corona/BANNER ENLACE UBBCOVID-07.png";
  public link_3 = "http://ubiobio.cl/covid19/";

  public img_4 = "assets/imgs/corona/BANNER RECURSOS COVID-06.png";
  public link_4 =
    "assets/imgs/corona/GUIA QUIMICA DESINFECTANTES DE SUPERFICIES.pdf";

  public ci2030: any;
  public video: string = "";
  public url_video: SafeHtml;
  public ci2030_autoridad: any;


  constructor(
    private _GlobalService: GlobalService,
    private translate: TranslateService,
    private _Ci2030Service: Ci2030Service,
    public sanitizer: DomSanitizer,
  ) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.GetCi2030();
    this.GetCi2030Autoridad();
  }

  ngOnInit() {
    VanillaTilt.init(document.querySelector(".tilt-image"), {
      max: 25,
      speed: 400,
    });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  public GetCi2030() {
    this.Loading = !this.Loading;
    this._Ci2030Service.GetCi2030Id(1).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          console.log('ci2030', datos);
          this.video = datos.video;
          if (this.video != null) {
            this.IframeFormat(this.video);
          }
          this.ci2030 = datos;
        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public GetCi2030Autoridad() {
    this.Loading = !this.Loading;
    this._Ci2030Service.GetCi2030Autoridad().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          console.log('ci2030_autoridad', datos);
          this.ci2030_autoridad = datos;
        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public IframeFormat(url_embed: string) {
    let video: SafeHtml;
    let url = `<iframe style="width: 100%; height: 500px;" src="${url_embed}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    video = this.sanitizer.bypassSecurityTrustHtml(url);
    this.url_video = video;
  }
}
