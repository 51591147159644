import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { DepartamentoService } from '../../services/departamento.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { SliderService } from '../../services/slider.service';
import { NoticiaService } from '../../services/noticia.service';
declare var VanillaTilt;

@Component({
  selector: 'app-noticia-vinculacion',
  templateUrl: './noticia-vinculacion.component.html',
  styleUrls: ['./noticia-vinculacion.component.css']
})
export class NoticiaVinculacionComponent implements OnInit {


  // 
  public imgPrincipal = "assets/imgs/vinculacion/vinculacion-medio/slide.jpg";
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public departamento = {
    cod_departamento: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    descripcion: "",
    descripcion_ingles: "",
    web_oficial: "",
    tipo: 0,
    base64: "",
    cambio: 0
  };

  public departamento_contacto = {
    cod_contacto: 0,
    cod_departamento: 0,
    nombre: "",
    cargo: "",
    sede: "",
    correo: "",
    ubicacion: "",
    fono: "",
    img: "",
    base64: "",
    cambio: 0
  };

  public Galeria = {
    galeria1: {},
    galeria2: {},
    galeria3: {},
    galeria4: {},
    galeria5: {},
    galeria6: {},
  };

  public ListadoNoticias: any;
  public Loading: boolean;

  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute,private _SliderService:SliderService, private _DepartamentoService: DepartamentoService, private _GlobalService: GlobalService,
    private _CarreraService: CarreraService, private _NoticiaService: NoticiaService, private translate: TranslateService) {
    // this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
    this.GETBanner();
    this.GETNoticia();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-departamento-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-departamento-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    VanillaTilt.init(document.querySelector(".tilt-image"), { max: 25, speed: 400 });
    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }


  public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(5).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETNoticia() {
    this._NoticiaService.GETNoticiaVistaId(3).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.ListadoNoticias = datos;
          sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }


  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

}
