import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContenidoService } from '../../services/contenido.service';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { DepartamentoService } from '../../services/departamento.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SliderService } from '../../services/slider.service';

@Component({
  selector: 'app-noticias-mas',
  templateUrl: './noticias-mas.component.html',
  styleUrls: ['./noticias-mas.component.css']
})
export class NoticiasMasComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public ListadoReciente: any;
  public ListadoNoticias: any;

  public ListadoEventos: any;
  constructor(private _Router: Router, private _ContenidoService: ContenidoService,private _SliderService:SliderService, private _GlobalService: GlobalService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoReciente = [];
    this.ListadoNoticias = [];

    let listado_noticias = JSON.parse(sessionStorage.getItem("listado_noticias"));
    let reciente = [];
    for (let i = 0; i < listado_noticias.length; i++) {
      reciente.push(listado_noticias[i]);
      if (i == 1) {
        break;
      }
    }
    let noticias = [];
    for (let i = 0; i < listado_noticias.length; i++) {
      noticias.push(listado_noticias[i]);
      if (i == 3) {
        break;
      }
    }
    this.ListadoReciente = reciente;
    this.ListadoNoticias = noticias;
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-noticias-mas"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-noticias-mas", JSON.stringify(false));
      window.location.reload();
    }
  }

 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {

  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public MasNoticias() {
    sessionStorage.setItem("reload-noticias-mas", JSON.stringify(true));
    this._Router.navigate(['/noticias-mas']);
  }

}
