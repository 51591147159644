import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { DepartamentoService } from '../../services/departamento.service';
import { CarreraService } from '../../services/carrera.service';
import { TranslateService } from '@ngx-translate/core';
import { SliderService } from '../../services/slider.service';
import { GraphService } from 'src/app/services/graph.service';
declare var VanillaTilt;


@Component({
  selector: 'app-facultad-organigrama',
  templateUrl: './facultad-organigrama.component.html',
  styleUrls: ['./facultad-organigrama.component.css']
})
export class FacultadOrganigramaComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public curriculum = {
    general: "general",
    publicacion: "publicacion",
    investigacion: "investigacion",
    ensenaza: "enseñanza",
    biografia: "biografia"
  }

  public autoridades = {
    decano: {},
    secretario_academico: {},
    secretaria: {},
    asistente_servicio: {},
    relaciones_publicas: {},
    diseno: {},
    jefe_proyecto: {},
    mantencion_bd: {},
    webmaster: {}
  }

  public general: boolean;
  public publicacion: boolean;
  public investigacion: boolean;
  public ensenaza: boolean;
  public biografia: boolean;
  public supervision: boolean;

  public texto_principal: string;

  constructor(private _Router: Router, private _GlobalService: GlobalService, private _SliderService: SliderService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService, private _GraphService: GraphService) {
    this.reload();
    //info
    this.general = true;
    this.publicacion = false;
    this.investigacion = false;
    this.ensenaza = false;
    this.biografia = false;
    //
    this.texto_principal = this.curriculum.general;
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
    this.GETBanner();
    this.QueryAutoridades();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-facultad-organigrama"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public QueryAutoridades() {
    this._GraphService.QueryAutoridades().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);

          console.log(datos.data.autoridades);
          datos.data.autoridades.forEach(element => {
            let ruta_img =  this._GlobalService.UrlArchivos() + "autoridades/" + element.img;

            switch (element.tipo) {
              case "decano":
                this.autoridades.decano = element;
                this.autoridades.decano["img"]  = ruta_img;
                break;
              case "secretario_academico":
                this.autoridades.secretario_academico = element;
                this.autoridades.secretario_academico["img"]  = ruta_img;
                break;
              case 'secretaria':
                this.autoridades.secretaria = element;
                this.autoridades.secretaria["img"]  = ruta_img;
                break;
              case 'asistente_servicio':
                this.autoridades.asistente_servicio = element;
                this.autoridades.asistente_servicio["img"]  = ruta_img;
                break;
              case 'relaciones_publicas':
                this.autoridades.relaciones_publicas = element;
                this.autoridades.relaciones_publicas["img"]  = ruta_img;
                break;
              case 'diseño':
                this.autoridades.diseno = element;
                this.autoridades.diseno["img"]  = ruta_img;
                break;
              case 'jefe_proyecto':
                this.autoridades.jefe_proyecto = element;
                this.autoridades.jefe_proyecto["img"]  = ruta_img;
                break;
              case 'mantencion_bd':
                this.autoridades.mantencion_bd = element;
                this.autoridades.mantencion_bd["img"]  = ruta_img;
                break;
              case 'webmaster':
                this.autoridades.webmaster = element;
                this.autoridades.webmaster["img"]  = ruta_img;
                break;
            }
            console.log(element)
          });
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {

    VanillaTilt.init(document.querySelector(".tilt-image"), { max: 25, speed: 400 });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }




  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }
 

  public InfoCurriculum(tipo) {
    switch (tipo) {
      case 1:
        this.general = true;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 2:
        this.general = false;
        this.publicacion = true;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 3:
        this.general = false;
        this.publicacion = false;
        this.investigacion = true;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 4:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = true;
        this.biografia = false;
        this.supervision = false;
        break;
      case 5:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = true;
        this.supervision = false;
        break;
      case 6:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = true;
        break;
    }
  }

}
