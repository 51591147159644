import { Component, OnInit } from '@angular/core';
import { NoticiaService } from '../../services/noticia.service';
import { GlobalService } from '../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';

@Component({
  selector: 'app-noticia-detalle',
  templateUrl: './noticia-detalle.component.html',
  styleUrls: ['./noticia-detalle.component.css']
})
export class NoticiaDetalleComponent implements OnInit {
  public descface = "http://ciencias.ubiobio.cl/web/src/archivos/PATRICIO%20N1589814126240.jpg";

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public noticia: any;
  public noticia_detalle = {
    cod_detalle: 0,
    cod_noticia: 0,
    titulo: "",
    subtitulo: "",
    descripcion: "",
    descripcion_ingles: "",
    link: "",
    img: "",
    base64: "",
    tipo: 0
  };

  public Noticias = {
    noticia1: {},
    noticia2: {},
    noticia3: {},
  }

  public Loading: boolean;

  constructor(private _NoticiaService: NoticiaService, private _ActivatedRoute: ActivatedRoute, private _SliderService: SliderService, private _GlobalService: GlobalService, private _Router: Router, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    // this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    // this.Loading = !this.Loading;
    // let timer = setInterval(() => {
    //   this.Loading = !this.Loading;
    //   clearInterval(timer);
    // }, 1500);
    // let info = JSON.parse(sessionStorage.getItem("noticia_detalle"));
    // let listado = JSON.parse(sessionStorage.getItem("noticia_listado"));
    // this.noticia = info.Noticia;
    // console.log(this.noticia);
    // this.OrdenarNoticias(listado);
    this.ListadoBanner = [];
    // this.GetNoticiaDetalle();
  }


  ngOnInit() {

    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        this.Loading = !this.Loading;
        this._NoticiaService.GETNoticiaId(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              this.noticia = datos;
              this.noticia.img = this._GlobalService.UrlArchivos() + this.noticia.img;
              console.log('Noticia', JSON.stringify(datos));
              this.GETNoticiaDetalle(cod);
              this.GETBanner();
              this.GETNoticia();

            } else {
              console.log('result', result)
            }
            this.Loading = !this.Loading;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
            this.Loading = !this.Loading;
          }
        );
      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-noticia-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-noticia-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETNoticiaDetalle(cod) {
    this._NoticiaService.GETNoticiaDetalle(cod).subscribe(
      result => {
        if (result.status === 200) {
          const datos: any = result.body;
          this.noticia_detalle = {
            ...datos,
            base64: this._GlobalService.UrlArchivos() + datos.base64,
            cod_noticia: this.noticia.cod_noticia
          };
  
          const replacements = [
            { search: 'class="ql-font-BAHNSCHRIFT"', replace: 'class="display-5"' },
            { search: '<p class="ql-align-justify">', replace: '<p class="display-5 ql-align-justify">' },
            { search: '<span s', replace: '<span class="display-5" align="justify" s' },
            { search: '<span style="background-color: transparent;">', replace: '<span class="display-5" align="justify" style="background-color: transparent;">' },
            { search: '<span style="background-color: transparent; color: rgb(0, 0, 0);">', replace: '<span class="display-5" align="justify">' },
            { search: '<strong style="background-color: transparent; color: rgb(0, 0, 0);">', replace: '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">' },
            { search: '<h1>', replace: '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">' },
            { search: '<li>', replace: '<li class="display-5" align="justify">' },
            { search: '<p>', replace: '<p class="display-5" align="justify">' },
            { search: '<h1', replace: '<h3' },
            { search: 'h1>', replace: 'h3>' },
            { search: '<strong', replace: '<h3' },
            { search: 'strong>', replace: 'h3>' },
            { search: '<h3>', replace: '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">' },
            { search: '<h3 s', replace: '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s' },
            { search: '<pre class="ql-syntax" spellcheck="false">', replace: '<p class="display-5" align="justify">' },
            { search: '</pre>', replace: '</p>' },
          ];
  
          const applyReplacements = (text) => {
            replacements.forEach(({ search, replace }) => {
              text = text.replace(new RegExp(search, 'g'), replace);
            });
            return text;
          };
  
          this.noticia_detalle.descripcion = applyReplacements(this.noticia_detalle.descripcion);
          this.noticia_detalle.descripcion_ingles = applyReplacements(this.noticia_detalle.descripcion_ingles);
  
          console.log('Noticia Detalle', JSON.stringify(datos));
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }
  

  public GETBanner() {
    this._SliderService.GETBannerId(7).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETNoticia() {
    this._NoticiaService.GETNoticia().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.OrdenarNoticias(datos);
          // this.ListadoNoticias = datos;
          // sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }



  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public GetNoticiaDetalle() {
    this.Loading = !this.Loading;
    this._NoticiaService.GETNoticiaDetalle(this.noticia.cod_noticia).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          this.noticia_detalle = datos;
          this.noticia_detalle.base64 = this._GlobalService.UrlArchivos() + this.noticia_detalle.base64;
          this.noticia_detalle.cod_noticia = this.noticia.cod_noticia;
          console.log('Noticia Detalle', JSON.stringify(datos));

        } else {
          console.log('result', result)
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public OrdenarNoticias(datos) {
    datos.slice(0, 3).forEach((dato, i) => {
      this.Noticias[`noticia${i + 1}`] = dato;
    });
  }
  

  public DetalleNoticias(noticia) {
    sessionStorage.setItem("noticia_detalle", JSON.stringify({ Noticia: noticia }));
    this.noticia = noticia;
    this.GetNoticiaDetalle();
  }
  
  public CompartirFacebook() {
    const urlpag = encodeURIComponent(`http://ciencias.ubiobio.cl/web/#/noticia-detalle/${this.noticia.cod_noticia}`);
    const w = 600, h = 300;
    const left = (screen.width / 2) - (w / 2);
    const top = (screen.height / 2) - (h / 2);
  
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${urlpag}`, this.noticia_detalle.titulo, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, width=${w}, height=${h}, top=${top}, left=${left}`);
    return false;
  }
  

}
