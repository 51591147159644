import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../../services/contenido.service';
import { GlobalService } from '../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { SliderService } from '../../services/slider.service';
import { EventoService } from '../../services/evento.service';
import { NoticiaService } from '../../services/noticia.service';
import { CarreraService } from '../../services/carrera.service';
import { HexagonoService } from '../../services/hexagono.service';
import { DepartamentoService } from '../../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { UsuarioService } from '../../services/usuario.service';
import { TallerService } from '../../services/taller.service';
import { FormularioService } from '../../services/formulario.service';

declare var VanillaTilt;

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {

  public formulario = {
    cod_formulario_respuesta: 0,
    cod_formulario: 0,
    nombre: "",
    descripcion: "",
    fecha: "",
    listado_item: []
  }

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public TextoTaller = "";

  public activeLang = 'es';

  public ListadoSlider: any;
  public ListadoHexagono: any;
  public ListadoTallerActual: any;

  public InicioSlider = "";

  public Loading: boolean;
  public Hexag: boolean;

  public RutaHexag = "";
  public TextoHexag = "";

  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, private _ContenidoService: ContenidoService, private _GlobalService: GlobalService,
    private _SliderService: SliderService, private _EventoService: EventoService, private _NoticiaService: NoticiaService,
    private _HexagonoService: HexagonoService, private _DepartamentoService: DepartamentoService, private _CarreraService: CarreraService,
    private _TallerService: TallerService, private translate: TranslateService, private _UsuarioService: UsuarioService,
    private _FormularioService: FormularioService) {


    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoSlider = [];
    this.ListadoBanner = [];
    this.ListadoHexagono = [];
    this.GETSlider();
    this.GETBanner();
  }




  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");

        this._FormularioService.GetFormularioCompletoFormatoId(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              console.log('formulario', datos);
              this.formulario = datos;

              let pnew = '<p class="display-5" align="justify" style="color: #293352;">';
              this.formulario.descripcion = this.formulario.descripcion.replace('<p>', pnew);
              console.log(this.formulario.descripcion);

            } else {
              console.log('result', result)
              this._GlobalService.ToastError();
            }
            // this.Loading = !this.Loading;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
              this._GlobalService.ToastError();
            }
            // this.Loading = !this.Loading;
          }
        );
      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public radioChange(event, campo) {
    campo.radio_opcion = event.cod_itemlistado;
  }

  public onChange(event, campo) {
    campo.cod_opcion = event.cod_itemlistado;
  }

  public toggle(event, item) {
    if (event == true) {
      item.valor = 1;
    } else {
      item.valor = 0;
    }
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public GETSlider() {
    this._SliderService.GETSlider().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioSlider = element["img"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoSlider = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public RedirectBanner(url) {
    window.open(url);
  }
 
  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public PostFormularioRespuesta(Datos) {
    this.Loading = !this.Loading;
    this._FormularioService.PostFormularioRespuesta(Datos).subscribe(
      result => {
        this.Loading = !this.Loading;
        if (result.status == 200) {
          let info = result.body;
          console.log(info)
          if (info == true) {
            this._GlobalService.ToastOk();
            // this.GetFormularioRespuesta(this.formulario.cod_formulario);
          }
        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public RegistrarRespuesta() {
    console.log(JSON.stringify(this.formulario));
    this.PostFormularioRespuesta(this.formulario);
  }

}
