import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarreraService } from '../../services/carrera.service';
import { GlobalService } from '../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';
import { EventoService } from '../../services/evento.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-evento-listado',
  templateUrl: './evento-listado.component.html',
  styleUrls: ['./evento-listado.component.css']
})
export class EventoListadoComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';
  public textoBusqueda = "";

  public EventoInicial: any;
  public Eventos = {
    evento1: {},
    evento2: {},
    evento3: {},
    evento4: {},
    evento5: {},
    evento6: {},
  }
  public ListadoEvento: any;
  public Largo = 0;
  public Loading: boolean;

  public page_size = 6;
  public page_number = 1;
  public pageSizeOptions = [3, 6, 9, 18, 36];
  public largo_listado = 0;

  constructor(private _Router: Router, private _CarreraService: CarreraService, private _SliderService: SliderService, private _GlobalService: GlobalService, private _DepartamentoService: DepartamentoService,
    private _EventoService: EventoService, private translate: TranslateService) {
    // this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    // this.Loading = !this.Loading;
    // let timer = setInterval(() => {
    //   this.Loading = !this.Loading;
    //   clearInterval(timer);
    // }, 1500);

    // let info = JSON.parse(sessionStorage.getItem("evento_listado"));
    // let listado = [];
    // for (let i = 0; i < info.length; i++) {
    //   if (i == 0) {
    //     this.EventoInicial = info[0];
    //   } else {
    //     listado.push(info[i]);
    //   }
    // }
    // this.OrdenarEventos(listado);
    // this.ListadoEvento = listado;
    // console.log(this.Eventos);
    this.GETEvento();
    this.ListadoBanner = [];
    this.GETBanner();

  }

  ngOnInit() {

  }

  public handlePage(e:PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-evento-listado"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-evento-listado", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETEvento() {
    this._EventoService.GETEvento().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            element["hora_inicio"] = element["hora_inicio"].substring(0, 5);
            element["hora_termino"] = element["hora_termino"].substring(0, 5);

          });
          console.log(datos);
          let info = datos;
          let listado = [];
          for (let i = 0; i < info.length; i++) {
            if (i == 0) {
              this.EventoInicial = info[0];
            } else {
              listado.push(info[i]);
            }
          }

          this.largo_listado = listado.length;
          this.OrdenarEventos(listado);
          this.ListadoEvento = listado;

        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public OrdenarEventos(datos: any[]) {
    const maxEventos = 6; // Define el número máximo de eventos
  
    // Asegúrate de que el array no supere el máximo permitido
    const eventosAAgregar = datos.slice(0, maxEventos);
  
    for (let i = 0; i < eventosAAgregar.length; i++) {
      this.Eventos[`evento${i + 1}`] = eventosAAgregar[i]; // Usando la notación de corchetes para asignar
    }
  }
  

  public DetalleEvento(evento) {
    let info = {
      Evento: evento
    };
    sessionStorage.setItem("reload-evento_detalle", JSON.stringify(true));
    sessionStorage.setItem("evento_detalle", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(['/evento-detalle/' + evento.cod_evento]);
  }


  public Actualizar() {
    this.Largo += 1; // Incrementar Largo
    let total = this.ListadoEvento.length;
    let limite = 6 + this.Largo;
  
    console.log(total);
    console.log(limite);
    console.log(total < limite);
  
    if (limite <= total) {
      for (let i = 0; i < 6; i++) {
        this.Eventos[`evento${i + 1}`] = this.ListadoEvento[i + this.Largo]; // Asignar eventos dinámicamente
      }
    }
  }
  

  public buscarEvento() {
    console.log(this.textoBusqueda);
    sessionStorage.setItem("reload-evento-busqueda", JSON.stringify(true));
    sessionStorage.setItem("evento-busqueda", JSON.stringify(this.textoBusqueda));
    this._Router.navigate(['/evento-busqueda']);
  }

  public Entrar(event) {
    let inputChar = event.charCode;
    if (inputChar == 13) {
      this.buscarEvento();
    }
  }
}
