import { Component, OnInit } from "@angular/core";
import { GlobalService } from "../services/global.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { SliderService } from "../services/slider.service";
import { HexagonoService } from "../services/hexagono.service";
import { TranslateService } from "@ngx-translate/core";
import { UsuarioService } from "../services/usuario.service";
import { GraphService } from "../services/graph.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

declare var VanillaTilt;

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.component.html",
  styleUrls: ["./inicio.component.css"],
  providers: [],
})
export class InicioComponent implements OnInit {
  public ListadoBanner: any[] = [];
  public ListadoSlider: any[] = [];
  public ListadoHexagono: any[] = [];

  public InicioBanner = "";
  public URLBanner = "";
  public InicioSlider = "";

  public activeLang = "es";
  public Loading = false;
  public Hexag = false;

  public RutaHexag = "";
  public TextoHexag = "";

  public Suscripcion = { correo: "" };
  public url_video: SafeHtml;

  constructor(
    private _Router: Router,
    private _GlobalService: GlobalService,
    private _SliderService: SliderService,
    private _HexagonoService: HexagonoService,
    private translate: TranslateService,
    private _UsuarioService: UsuarioService,
    private _GraphService: GraphService,
    public sanitizer: DomSanitizer
  ) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoSlider = [];
    this.ListadoBanner = [];
    this.ListadoHexagono = [];
    this.QueryBrief(4);
    this.GETSlider();
    this.GETBanner();
    this.GETHexagono();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-inicio"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-inicio", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    VanillaTilt.init(document.querySelector(".tilt-image"), {
      max: 25,
      speed: 400,
    });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  public QueryBrief(cod_brief) {
    this._GraphService.QueryBrief(cod_brief).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let video: SafeHtml;
          let url = datos.data.brief.descripcion;
          url = `<iframe style="width: 100%; height: 500px;" src="${url}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
          video = this.sanitizer.bypassSecurityTrustHtml(url);

          this.url_video = video;
          console.log("url_video", url);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //hexagonos

  public RedireccionarHexa(tipo: number) {
    const rutas = [
      "/hexagono-academico",
      "/hexagono-bachillerato",
      "/hexagono-doctorado",
      "/hexagono-magister",
      "/hexagono-pregrado",
    ];
    this._Router.navigate([rutas[tipo - 1]]);
  }

  public GETSlider() {
    this._SliderService.GETSlider().subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            // element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            element["img"] =
              "http://ciencias.ubiobio.cl/web/mantenedor_v2/views/uploads/sliders/" +
              element["img"];
            if (i == 0) {
              this.InicioSlider = element["img"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoSlider = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  private Validaciones(): boolean {
    return !!this.Suscripcion.correo;
  }

  private PostSuscripcion(Datos: any) {
    this.Loading = true;
    this._UsuarioService.PostSuscripcion(Datos).subscribe(
      (result) => {
        this.Loading = false;
        if (result.status === 200 && result.body === true) {
          console.log("Suscripción exitosa");
        }
      },
      (err: HttpErrorResponse) => {
        this.Loading = false;
        console.error("Error:", err.message);
      }
    );
  }

  public RegistrarSuscripcion() {
    if (this.Validaciones()) {
      this.PostSuscripcion(this.Suscripcion);
    }
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public GETHexagono() {
    this._HexagonoService.GETHexagono().subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.ListadoHexagono = datos;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public ModalHexagono(opcion) {
    let rutaHexag = "assets/imgs/hexagono/" + opcion + ".png";
    let texto = "";
    switch (opcion) {
      case "01":
        texto = "VER ACADÉMICOS";
        break;
      case "02":
        texto = "VER BACHILLERATO";
        break;
      case "03":
        texto = "VER PROGRAMAS DE DOCTORADO";
        break;
      case "04":
        texto = "VER PROGRAMAS DE MAGÍSTER";
        break;
      case "05":
        texto = "VER PREGRADOS";
        break;
      case "06":
        texto = "VER PUBLICACIONES";
        break;
      case "07":
        texto = "Departamento de Ciencias Básicas".toUpperCase();
        break;
      case "08":
        texto = "Departamento de Estadística".toUpperCase();
        break;
      case "09":
        texto = "Departamento de Física".toUpperCase();
        break;
      case "10":
        texto = "Departamento de Matemática".toUpperCase();
        break;
      case "11":
        texto = "Departamento de de Química".toUpperCase();
        break;
    }
    this.TextoHexag = texto;
    this.RutaHexag = rutaHexag;
    this.Hexag = !this.Hexag;
  }

  public CerrarHexagono() {
    this.Hexag = !this.Hexag;
  }

  public MostrarTituloHex(opcion) {
    let rutaHexag = "assets/imgs/hexagono/" + opcion + ".png";
    let texto = "";
    switch (opcion) {
      case "01":
        texto = "ACADÉMICOS";
        break;
      case "02":
        texto = "BACHILLERATO";
        break;
      case "03":
        texto = "PROGRAMAS DE DOCTORADO";
        break;
      case "04":
        texto = "PROGRAMAS DE MAGÍSTER";
        break;
      case "05":
        texto = "PREGRADOS";
        break;
      case "06":
        texto = "PUBLICACIONES";
        break;
      case "07":
        texto = "Departamento Ciencias Básicas".toUpperCase();
        break;
      case "08":
        texto = "Departamento Estadística".toUpperCase();
        break;
      case "09":
        texto = "Departamento Física".toUpperCase();
        break;
      case "10":
        texto = "Departamento Matemática".toUpperCase();
        break;
      case "11":
        texto = "Departamento de Química".toUpperCase();
        break;
    }
    this.TextoHexag = texto;
    this.RutaHexag = rutaHexag;
  }

  public RediHexagono(opcion) {
    let ruta = "";
    switch (opcion) {
      case "01":
        // ruta = "VER ACADEMICOS";
        ruta = "/";
        break;
      case "02":
        ruta = "/";
        // ruta = "VER BACHILLERATO";
        break;
      case "03":
        ruta = "/";
        // ruta = "VER PROGRAMAS DE DOCTORADO";
        break;
      case "04":
        ruta = "/";
        // ruta = "VER PROGRAMAS DE MAGISTER";
        break;
      case "05":
        ruta = "/";
        // ruta = "VER PREGRADOS";
        break;
      case "06":
        ruta = "/publicaciones-total";
        // ruta = "VER PUBLICACIONES";
        break;
      case "07":
        ruta = "/";
        // ruta = "Depto Cs. Básicas".toUpperCase();
        break;
      case "08":
        ruta = "/";
        // ruta = "Depto Estadística".toUpperCase();
        break;
      case "09":
        ruta = "/";
        // ruta = "Depto Física".toUpperCase();
        break;
      case "10":
        ruta = "/";
        // ruta = "Depto Matemática".toUpperCase();
        break;
      case "11":
        ruta = "/";
        // ruta = "Depto de Química".toUpperCase();
        break;
    }
    this._Router.navigate(["/publicaciones-total"]);
  }
}
