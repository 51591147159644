import { Component, OnInit } from "@angular/core";
import { GlobalService } from "../../services/global.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { NoticiaService } from "../../services/noticia.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'app-noticia-inicio',
  templateUrl: './noticia-inicio.component.html',
  styleUrls: ['./noticia-inicio.component.css']
})
export class NoticiaInicioComponent implements OnInit {

  public activeLang = "es";
  public ListadoNoticias: any;
  public Noticias = {};  // Simplificado para asignar dinámicamente
  public Loading: boolean;

  constructor(
    private _Router: Router,
    private _GlobalService: GlobalService,
    private _NoticiaService: NoticiaService,
    private translate: TranslateService,
    public sanitizer: DomSanitizer
  ) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoNoticias = [];
    this.GETNoticia();
  }


  ngOnInit() {
  }


  public MasNoticias() {
    sessionStorage.setItem("reload-noticias-mas", JSON.stringify(true));
    this._Router.navigate(["/noticias-mas"]);
  }

  public GETNoticia() {
    this._NoticiaService.GETNoticia().subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];

            var desc = element["titulo"];
            if (element["titulo"].length >= 55) {
              desc = element["titulo"].substring(0, 55) + "...";
            }

            var desc_in = element["titulo_ingles"];
            if (element["titulo_ingles"].length >= 55) {
              desc_in = element["titulo_ingles"].substring(0, 55) + "...";
            }

            element["desc_corto"] = desc;
            element["desc_corto_ingles"] = desc_in;
          });
          this.OrdenarNoticias(datos);
          this.ListadoNoticias = datos;
          sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public OrdenarNoticias(datos) {
    const numeroNoticias = Math.min(datos.length, 6);  // Máximo de 6 noticias
    for (let i = 0; i < numeroNoticias; i++) {
      this.Noticias[`noticia${i + 1}`] = datos[i];
    }
  }

  public DetalleNoticias(noticia) {
    let info = {
      Noticia: noticia,
    };
    sessionStorage.setItem("reload-noticia_detalle", JSON.stringify(true));
    sessionStorage.setItem("noticia_detalle", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(["/noticia-detalle/" + noticia.cod_noticia]);
  }

}
