import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { HexagonoService } from '../../services/hexagono.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DepartamentoService } from '../../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { SliderService } from '../../services/slider.service';
declare var VanillaTilt;


@Component({
  selector: 'app-facultad-recursos-educacion-moodle',
  templateUrl: './facultad-recursos-educacion-moodle.component.html',
  styleUrls: ['./facultad-recursos-educacion-moodle.component.css']
})
export class FacultadRecursosEducacionMoodleComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = 'es';


  public ListadoHexagono: any;
  public Loading: boolean;
  public imgPrincipal = "assets/imgs/historia/facultad.png";


  public img_m1 = "assets/imgs/recursos_educacion/moodle/Tutorial de Acceso y Navegación Plataforma Moodle UBB  PERFIL ESTUDIANTE.png";
  public pdf_m1 = "assets/imgs/recursos_educacion/moodle/Tutorial de Acceso y Navegación Plataforma Moodle UBB  PERFIL ESTUDIANTE.pdf";

  public img_m2 = "assets/imgs/recursos_educacion/moodle/Tutorial ACTIVACIÓN MOODLE-ZOOM (Versión 2. 30-03-2020).png";
  public pdf_m2 = "assets/imgs/recursos_educacion/moodle/Tutorial ACTIVACIÓN MOODLE-ZOOM (Versión 2. 30-03-2020).pdf";

  constructor(private _Router: Router, private _GlobalService: GlobalService, private _SliderService: SliderService, private _HexagonoService: HexagonoService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoHexagono = [];
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-facultad-historia"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-facultad-historia", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {

    VanillaTilt.init(document.querySelector(".tilt-image"), { max: 25, speed: 400 });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public AbrirDocumento(tipo) {
    let url = "";
    switch (tipo) {
      case 1:
        url = this.pdf_m1;
        break;
      case 2:
        url = this.pdf_m2;
        break;
    }
    window.open(url);
  }

}
