import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { CarreraService } from '../../services/carrera.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';
import { NoticiaService } from '../../services/noticia.service';
import { EventoService } from '../../services/evento.service';

@Component({
  selector: 'app-galeria-carrera',
  templateUrl: './galeria-carrera.component.html',
  styleUrls: ['./galeria-carrera.component.css']
})
export class GaleriaCarreraComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public carrera_extra = { cod_extra: 0, cod_carrera: 0, descripcion: "" };

  public carrera = {
    cod_carrera: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    tipo: 1,
    web_oficial: "",
    face: "",
    insta: "",
    logo: "",
    base64_logo: "",
    cambio_logo: 0,
    base64: "",
    cambio: 0
  };

  public Galeria = {
    galeria1: {},
    galeria2: {},
    galeria3: {},
    galeria4: {},
    galeria5: {},
    galeria6: {},
  };

  public ListadoGaleria: any;
  public Loading: boolean;


  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, private _SliderService: SliderService, private _CarreraService: CarreraService, private _GlobalService: GlobalService, private _DepartamentoService: DepartamentoService,
    private _NoticiaService: NoticiaService, private _EventoService: EventoService, private translate: TranslateService) {
    this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-carrera-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-carrera-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        this._CarreraService.GETCarreraID(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              console.log("Carrera", datos);
              if (datos != null) {
                datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
                datos["logo"] = this._GlobalService.UrlArchivos() + datos["logo"];

                sessionStorage.setItem("reload-carrera_detalle", JSON.stringify(true));
                sessionStorage.setItem("carrera_detalle", JSON.stringify(datos));
                this.carrera = datos;
                this.GetCarreraGaleria();
                if (this.carrera.tipo == 1) {
                  this.GETBanner(3);
                } else {
                  this.GETBanner(4);
                }
              }
            } else {
              console.log('result', result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
          }
        );

      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public GetCarreraGaleria() {
    this.Loading = !this.Loading;
    this._CarreraService.GetCarreraGaleria(this.carrera.cod_carrera).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element.base64 = this._GlobalService.UrlArchivos() + element.base64;
          });

          let listado = []

          for (let i = 1; i < datos.length; i++) {

            listado.push(datos[i]);

          }

          this.ListadoGaleria = listado;
          this.OrdenarGaleria(datos);

        } else {
          console.log('result', result)
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public OrdenarGaleria(datos) {
    for (let i = 0; i < datos.length; i++) {
      switch (i + 1) {
        case 1:
          this.Galeria.galeria1 = datos[i];
          break;
        case 2:
          this.Galeria.galeria2 = datos[i];
          break;
        case 3:
          this.Galeria.galeria3 = datos[i];
          break;
        case 4:
          this.Galeria.galeria4 = datos[i];
          break;
        case 5:
          this.Galeria.galeria5 = datos[i];
          break;
        case 6:
          this.Galeria.galeria6 = datos[i];
          break;
      }
      if (i == 5) {
        break;
      }
    }
  }


  public RedirectPag(url) {
    window.open(url);
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner(tipo) {
    this._SliderService.GETBannerId(tipo).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }


}
