import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GraphService } from '../services/graph.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public activeLang = 'es';
  public autoridades = {
    secretaria: {},
    relaciones_publicas: {},
  }
  public redes_sociales = {
    instagram: {},
    facebook: {},
    youtube: {},
    twitter: {},
    linkedin: {}
  }

  constructor(private _Router: Router, private translate: TranslateService, private _GraphService: GraphService) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.QueryAutoridades();
    this.QueryRedesSociales();
  }

  ngOnInit(): void {
  }

  public QueryAutoridades() {
    this._GraphService.QueryAutoridades().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);

          datos.data.autoridades.forEach(element => {
            switch (element.tipo) {
              case 'secretaria':
                this.autoridades.secretaria = element;
                break;
              case 'relaciones_publicas':
                this.autoridades.relaciones_publicas = element;
                break;
            }
          });
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public QueryRedesSociales() {
    this._GraphService.QueryRedesSociales().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);

          datos.data.redes_sociales.forEach(element => {
            switch (element.red_social) {
              case 'instagram':
                this.redes_sociales.instagram = element;
                break;
              case 'facebook':
                this.redes_sociales.facebook = element;
                break;
              case 'youtube':
                this.redes_sociales.youtube = element;
                break;
              case 'twitter':
                this.redes_sociales.twitter = element;
                break;
              case 'linkedin':
                this.redes_sociales.linkedin = element;
                break;
            }
          });
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

}
